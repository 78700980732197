import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo/pdlogo.png";
import { Link, useNavigate } from "react-router-dom";
import FirstButton from "../common/button";
import SideBar from "./SideBar";
import ArrowDown from "../assets/images/icons/arrow_down.svg";
import { role } from "../utils/index";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton, Avatar, Menu, MenuItem } from "@mui/material";

const Header = () => {
  const [openNav, setOpenNav] = useState(false);
  const [show, setShow] = useState(false);
  const [userrole, setuserRole] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let item = localStorage.getItem("role");
    if (item !== undefined && item !== null && item !== "") {
      setuserRole(item);
    }
  });

  const handleLogin = () => {
    navigate("/login");
  };
  const handleSignup = () => {
    navigate("/sign-up");
  };

  const handleSidebar = () => {
    if (!openNav) {
      setOpenNav(true);
    } else {
      setOpenNav(false);
    }
  };
  // const handleClose = () => {
  //   setOpenNav(false);
  // };
  const handleShow = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const handlelogout = () => {
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    setShow(false);
    navigate("/");
    window.location.reload();
  };

  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenNav(false);
  };
  return (
    <>
      <SideBar getValue={handleClose} classValue={openNav} />
      <nav className="navbar">
        <div className="navbar_header">
          <div className="navbar-brand">
            <a className="d-flex" href="/">
              <img className="brand-logo" alt="Logo" src={Logo} />
              <div style={{ lineHeight: "18px" }}>
                <span className="navbar_logo">PharmaDigm</span>
                <p className="fs-12 text-white ps-2 text-start mb-0">
                  Connect & Grow
                </p>
              </div>
            </a>
          </div>

          <div className="navigation">
            <ul className="navigation_list mb-0">
              <li className="navigation_list--link mt-2">
                <Link className="navigation_list--link" to="/">
                  Home
                </Link>
              </li>

              <li className="navigation_list--link mt-2">
                <Link className="navigation_list--link" to="/about-us">
                  About Us
                </Link>
              </li>

              <li className="navigation_list--link mt-2">
                <Link className="navigation_list--link" to="/faq">
                  FAQs
                </Link>
              </li>

              <li className="navigation_list--link mt-2">
                <Link className="navigation_list--link" to="/contact-us">
                  Contact Us
                </Link>
              </li>

              {localStorage?.getItem("name") === "" ||
              localStorage?.getItem("name") === null ? (
                <>
                  <li className="navigation_list--link">
                    <FirstButton
                      buttonText="Login"
                      className="borderbtn"
                      onClick={handleLogin}
                    />
                  </li>
                  <li className="navigation_list--link">
                    <FirstButton
                      buttonText="Sign Up"
                      className="borderbtn"
                      onClick={handleSignup}
                    />
                  </li>
                </>
              ) : (
                <>
                  <li className="navigation_list--link mt-2">
                    <Link
                      className="navigation_list--link"
                      to="/dossier-search"
                    >
                      Dossier Finder
                    </Link>
                  </li>
                  <li className="navigation_list--link mt-2">
                    <Link
                      className="navigation_list--link"
                      to="/supplier-search"
                    >
                      Supplier Finder
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>

          {localStorage?.getItem("name") === "" ||
          localStorage?.getItem("name") === null ? (
            <div className="auth">
              <div
                className=""
                style={{ display: "flex", justifyContent: "end" }}
              >
                <IconButton className="icon-background">
                  <FacebookIcon className="fs-18 text-white wh-25" />
                </IconButton>
                <IconButton className="icon-background">
                  <LinkedInIcon className="fs-18 text-white wh-25" />
                </IconButton>
                <IconButton className="icon-background">
                  <TwitterIcon className="fs-18 text-white wh-25" />
                </IconButton>
              </div>
            </div>
          ) : (
            <>
              {/* <div className="dropdown">
                <button className="dropbtn ">
                  <p>{localStorage?.getItem("name")}</p>
                  <p>
                    <img
                      src={ArrowDown}
                      style={{ marginTop: "0.8rem" }}
                      alt="arrow"
                    />
                  </p>
                </button>
                <div className="dropdown-content">
              
                  <Link onClick={handlelogout}>Logout</Link>
                </div>
              </div> */}

              <div
                className="d-flex"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Avatar>
                  {localStorage?.getItem("name")?.charAt(0).toUpperCase()}
                </Avatar>

                <p className="ms-3 fs-16 fw-600 text-white text-capitalize m-0">
                  {localStorage?.getItem("name")?.split(" ")[0]}
                </p>
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem style={{ width: "100px" }} onClick={handlelogout}>
                  Logout
                </MenuItem>
              </Menu>
            </>
          )}
          <div onClick={handleSidebar} className="navigation_menu">
            <div></div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
