import React from "react";

import img from "../../assets/images/addedNew/aboutus.jpg";
import bhuvanaImage from "../../assets/images/addedNew/bhuvana-photo.jpg.png";
import anilImage from "../../assets/images/addedNew/anil-photo.png";

const Main = () => {
  return (
    <>
      <div
        className="about-main bg-white "
        style={{ backgroundColor: "#ebebeb" }}
      >
        <div style={{ position: "relative", textAlign: "center" }}>
          {/* <img
            src={img}
            className="w100"
            style={{
              filter: "opacity(0.65)",
              backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0) 80%)`,
            }}
              
          /> */}
          <img
            src={img}
            className="w100"
            style={{
              filter: "opacity(0.65)",
              width: "100%",
            }}
            alt=""
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.8) 40%, rgba(0, 0, 0, 0) 65%)`,
            }}
          />
          <div className="aboutText">
            We are a company who believes that in today's age information should
            be available just a few clicks away.
          </div>
        </div>

        <h2
          className="faq_f1 faq_mr mt-5 mb-5 dark about_heading"
          style={{ textAlign: "center" }}
        >
          Who We Are
        </h2>
        {/* <p className="fs-16 fw-600">TEAM PharmaDigm!</p> */}
        <div style={{ display: "flex", margin: "0 2rem" }}>
          <img src={bhuvanaImage} alt="" class="profile-image" />
          <p className="fs-14 fw-500 text-justify mb-4 profile-text">
            <span className="fw-600"> Bhuvana Mathur</span> has over 20 years
            experience in business development, licensing and product portfolio
            management in pharmaceuticals. With a strong background in Mergers
            and Acquisitions, Business transformation, Sales and Marketing,
            Corporate Strategy across regulated markets and rest of the world
            geographies and e-commerce experience with US companies, she has
            established herself as a leader driving business growth in the
            pharma world. She holds a Masters in Business Administration from
            SPJIMR, a prestigious institution in India.
          </p>
        </div>

        <div style={{ display: "flex", margin: "0 2rem" }}>
          <p className="fs-14 fw-500 text-justify mb-4 profile-text">
            With over 25 years of experience,
            <span className="fw-600"> Dr. Anil Kumar </span> has a proven track
            record in product development, regulatory affairs, and intellectual
            property. With a development portfolio of 300+ products, several
            patents to his name in formulations research and novel drug delivery
            technologies, & leadership positions in multi-national companies, he
            is recognized as a leading expert in the industry. He holds a
            Masters Degree and a PhD in Pharmacy.
          </p>
          <img src={anilImage} alt="" class="profile-image" />
        </div>

        <div className="only-d-flex about-class  justify-content-between px-5 m-t-6-rem  ">
          <div className="px-4 w50">
            <h2
              className="faq_f1 faq_mr mb-5 dark about_heading"
              style={{ textAlign: "center" }}
            >
              What we believe
            </h2>
            {/* <p className="fs-16 fw-600">Ditch the Pharma Shuffle!</p> */}
            <p className="fs-17 fw-500 text-justify mb-4">
              The pharma industry has long relied on inefficient, time-consuming
              methods to connect suppliers and buyers. Endless calls, emails,
              and networking events have hindered the pace of collaboration. Our
              mission is to create a
              <span className="fw-600"> dynamic marketplace </span> that brings
              together pharmaceutical stakeholders on a
              <span className="fw-600">single platform.</span>
            </p>
            <p className="fs-17 fw-500 text-justify">
              By leveraging
              <span className="fw-600">technology and data, </span>
              we aim to simplify the complex process of
              <span className="fw-600"> partner identification, </span>
              enabling businesses to focus on core competencies rather than
              time-consuming searches. Our goal is to transform the industry by
              providing a smarter, faster, and more efficient way to
              <span className="fw-600"> connect and collaborate.</span>
            </p>
          </div>
          <div className="px-4 w50">
            <h2
              className="faq_f1 faq_mr mb-5 dark about_heading"
              style={{ textAlign: "center" }}
            >
              Who we cater to
            </h2>

            <p className="fs-17 fw-500 text-justify mb-4">
              We are dedicated to streamlining interactions within the
              pharmaceutical industry. Our platform is designed for
            </p>
            <p className="fs-17 fw-500 text-justify text-justify ms-5">
              <ul style={{ padding: "0", listStyleType: "inherit" }}>
                <li className="mb-2">
                  <span className="fw-600">
                    Business Development professionals :
                  </span>
                  To identify and secure new business opportunities,
                  <span className="fw-600">
                    dossier licensing, CDMO partner identification
                  </span>
                  through our comprehensive database.
                </li>
                <li className="mb-2">
                  <span className="fw-600"> Contract Manufacturers:</span>
                  Expand your market reach by connecting with
                  <span className="fw-600">potential clients </span>
                  seeking your expertise.
                </li>
                <li className="mb-2">
                  <span className="fw-600"> FDF Suppliers: </span>
                  Showcase your
                  <span className="fw-600">
                    dossiers and discover new business partnerships
                  </span>
                </li>
                <li className="mb-2">
                  <span className="fw-600">Research & Development: </span>
                  Access a range of
                  <span className="fw-600"> ancillary services </span> to
                  enhance your project efficiency and speed
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
