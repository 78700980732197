// AppRouter.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../screens/Login";
import Forgot from "../screens/Forgot";
import Home from "../screens/Home";
import Header from "../navigation/Header";
import ProtectedRoute from "./ProtectedRoutes";
import Footer from "../navigation/Footer";
import HowItWorks from "../screens/HowItWorks";
import Welcome from "../screens/Welcome";
import Registration from "../screens/Registration";
import Faq from "../screens/Faq";
import ContactUs from "../screens/ContactUs";
import SignUp from "../screens/Signup";
import ResetPassword from "../screens/ResetPassword";
import SupplierTermSheet from "../screens/SupplierTermSheet";
import Landing from "../screens/Landing";
import AboutUs from "../screens/AboutUs";
import ProductDetails from "../screens/ProductDetailsPage";
import SupplierSearchPage from "../screens/SupplierSearchPage";
import ProductSearchPage from "../screens/ProductSearchPage";
import SupplierDetails from "../screens/SupplierDetailsPage";
import ProductTermSheet from "../screens/ProductTermSheet";
import Service from "../screens/Service";
const AppRouter = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/how-it-works" element={<HowItWorks />} />

        <Route path="/registration" element={<Registration />} />

        <Route path="/faq" element={<Faq />} />
        <Route path="/contact-us" element={<ContactUs />} />

        <Route path="/login" element={<Login />} />
        <Route
          path="reset-password/:key/:emailId"
          element={<ResetPassword />}
        />

        <Route path="/forgot" element={<Forgot />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/about-us" element={<AboutUs />} />

        {/* -------------------Admin Routes------------------------------------ */}
        <Route
          exact
          path="/admin"
          element={<ProtectedRoute allowedRoles={["admin"]} />}
        >
          {/* <Route exact path="/admin/data-form" element={<FillDetails />} /> */}
        </Route>

        <Route
          exact
          path="/"
          element={<ProtectedRoute allowedRoles={["seller", "admin"]} />}
        >
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/home" element={<Home />} />
          <Route path="/dossier-search" element={<ProductSearchPage />} />
          <Route path="/product-details/:id" element={<ProductDetails />} />
          <Route
            path="/product-term-sheet/:id"
            element={<ProductTermSheet />}
          />
          <Route path="/supplier-search" element={<SupplierSearchPage />} />
          <Route path="/supplier-details/:id" element={<SupplierDetails />} />
          <Route
            path="/supplier-term-sheet/:id"
            element={<SupplierTermSheet />}
          />
          <Route path="/service" element={<Service />} />
        </Route>
      </Routes>

      <Footer />
    </Router>
  );
};

export default AppRouter;
