import React from "react";
import { useNavigate } from "react-router-dom";
import img from "../../assets/images/puzzal.png";
import FirstButton from "../../common/button";
import { role } from "../../utils/index";
// import bgImag from "../../assets/images/addedNew/jigsaw.jpg";
import bgImag from "../../assets/images/addedNew/jigsaw-new.jpeg";
const MarketPlaceMain = () => {
  const userrole = localStorage.getItem("role");
  const navigate = useNavigate();
  const handelNavigate = () => {
    if (userrole === "marketplace") {
      navigate("/marketplace/techofferlisting");
      window.location.reload();
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <div
        className="p6"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.9) 40%, rgba(0, 0, 0, 0) 65%), url(${bgImag})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "#EBEBEB ",
          marginTop: "8rem",
        }}
      >
        <div className="main_text mt-5">
          <h1 className=" news_heading dark" style={{ marginBottom: "0.8rem" }}>
            Perfect Fit, Effortless Search
          </h1>
          <h2 className="fw-600" style={{ marginBottom: "5rem" }}>
            Sourcing Made Simple.
          </h2>
        </div>
        <div className=" main_container1">
          <div className="w100">
            <p className="secondaryText dark text-justify">
              <ul style={{ padding: "0" }}>
                <li className="mb-4">
                  Power up pipeline through
                  <span className="fw-600"> global products</span>
                </li>
                <li className="mb-4">
                  Expert curated for
                  <span className="fw-600"> quality dossiers</span>
                </li>
                <li className="mb-4">
                  <span className="fw-600">Detailed supplier information</span>
                  for matchmaking
                </li>
                <li className="mb-4">
                  <span className="fw-600"> Additional services </span> for
                  Seamless transactions
                </li>
              </ul>
            </p>
            {/* {userrole === role[2].role ? (
              <FirstButton
                className="btn"
                float="left"
                marginTop="10rem"
                buttonText="Explore Marketplace"
                onClick={handelNavigate}
              />
            ) : (
              <FirstButton
                className="btn"
                float="left"
                marginTop="10rem"
                buttonText="Contact Us"
                onClick={() => navigate("/signup")}
              />
            )} */}
            <FirstButton
              className="btn"
              float="left"
              marginTop="3rem"
              buttonText="FIND DOSSIERS/ PARTNERS RIGHT AWAY"
              onClick={() =>
                localStorage?.getItem("token")
                  ? navigate("/home")
                  : navigate("/login")
              }
            />
          </div>

          {/* <div className="main_content w50 jigsaw">
            <img src={img} className="w100" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default MarketPlaceMain;
