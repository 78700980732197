import React, { useEffect, useState } from "react";
import FirstButton from "../common/button";

import BreadCrumbs from "./BreadCrumbs";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
const ProductDetails = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [productdetails, setProductdetails] = useState([]);
  const [dealCounter, setDealCounter] = useState([]);
  const [manufDetails, setManufDetails] = useState([]);
  let { id } = useParams();
  const urls = [
    { name: "Home", url: "/home" },
    { name: "Dossier Search", url: "/dossier-search" },
    { name: "Product Details", url: "" },
  ];
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
    getProductDetails();
  }, []);
  const getProductDetails = () => {
    const data = { id: id };
    axios
      .post(process.env.REACT_APP_BACKEND + "get-dossierbyid", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((resp) => {
        setData(resp?.data?.data);
        setProductdetails(
          convertProductDetails(resp?.data?.data?.productDetails)
        );
        setDealCounter(convertDealCounter(resp?.data?.data?.dealContours));
        setManufDetails(
          convertmanufDetails(resp?.data?.data?.manufacturingDetails)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertProductDetails = (productDetails) => {
    return [
      {
        label: "Dose Form 1",
        value: productDetails?.doseForm1,
      },
      {
        label: "Dose Form 2",
        value: productDetails?.doseForm2,
      },
      {
        label: "Route of Administration",
        value: productDetails?.routeOfAdministration,
      },
      { label: "Strengths", value: productDetails?.strengths },
      { label: "Salt", value: productDetails?.saltBase },
      {
        label: "Therap. Category",
        value: productDetails?.therapCategory,
      },
      {
        label: "ATC Class",
        value: productDetails?.atcClass,
      },
      {
        label: "Indication",
        value: productDetails?.indication,
      },
      {
        label: "US Ref product*",
        value: productDetails?.usRefProduct,
      },
      {
        label: "US Constraint date",
        value: productDetails?.usConstraintDate,
      },
      { label: "Rx/ OTC", value: productDetails?.rxOtc },
      {
        label: "Pack style 1",
        value: productDetails?.packStyle1,
      },
      {
        label: "Pack sizes",
        value: productDetails?.packSizes1.join(", "),
      },
      {
        label: "Pack style 2",
        value: productDetails?.packStyle2,
      },
      {
        label: "Pack sizes",
        value: productDetails?.packSizes2.join(", "),
      },
      {
        label: "CT/ BE details",
        value: productDetails?.ctBeDetails,
      },
      {
        label: "Dossier status",
        value: productDetails?.dossierStatus.ReadyUS
          ? "Ready EU"
          : productDetails?.dossierStatus.EarlyDevelopment
          ? "Early Development"
          : productDetails?.dossierStatus.LateStage
          ? " Late Stage"
          : "Ready US",
      },
      {
        label: "Registered in",
        value: productDetails?.registeredIn,
      },
    ];
  };
  const convertDealCounter = (dealCounter) => {
    return [
      {
        label: "Available geographies",
        value: dealCounter?.availableGeographies,
      },
      {
        label: "Supply status",
        value: dealCounter.supplyStatus.WithSupplyOnly
          ? "With Supply"
          : "No commercial supplies",
      },
      {
        label: "Tech transfer support",
        value: dealCounter?.techTransferSupport,
      },
      {
        label: "Deal type",
        value: dealCounter?.dealType,
      },
      {
        label: "Exclusivity",
        value: dealCounter?.exclusivity,
      },
      { label: "MA holder", value: dealCounter?.maHolder },
      { label: "IP owner", value: dealCounter?.ipOwner },
      {
        label: "Details",
        value: dealCounter?.details,
      },
      {
        label: "One time fee",
        value: dealCounter?.oneTimeFee,
      },
      { label: "Royalty", value: dealCounter?.royalty },
      {
        label: "Profit share",
        value: dealCounter?.profitShare,
      },
    ];
  };
  const convertmanufDetails = (manufacturingDetails) => {
    return [
      {
        label: "R&D details",
        value: manufacturingDetails.rAndDDetails,
      },
      {
        label: "Submission batch mfg",
        value: manufacturingDetails.submissionBatchMfg,
      },
      {
        label: "GMP status",
        value: manufacturingDetails.gmpStatus,
      },
      {
        label: "Last Inspection",
        value: manufacturingDetails.lastInspection,
      },
      {
        label: "Country of manufacture",
        value: manufacturingDetails.countryOfManufacture.Asia
          ? "Asia"
          : manufacturingDetails.countryOfManufacture.Europe
          ? "Europe"
          : manufacturingDetails.countryOfManufacture.NorthAmerica
          ? "North America"
          : "Rest of the World",
      },
      {
        label: "# of API sources",
        value: manufacturingDetails.numberOfApiSources,
      },
      {
        label: "API type",
        value: manufacturingDetails.apiType,
      },
      { label: "API DMF", value: manufacturingDetails.apiDmf },
      {
        label: "CPP country",
        value: manufacturingDetails.cppCountry,
      },
      {
        label: "Batch size",
        value: manufacturingDetails.batchSize,
      },
      { label: "MOQ", value: manufacturingDetails.moq },
      { label: "CRO", value: manufacturingDetails.cro },
      {
        label: "Stability ",
        value: `${manufacturingDetails.stability.accelerated} / ${manufacturingDetails.stability.real}`,
      },
      {
        label: "Stability Zones",
        value: manufacturingDetails.stabilityZones[0],
      },
      {
        label: "",
        value: manufacturingDetails.stabilityZones[1],
      },
      {
        label: "",
        value: manufacturingDetails.stabilityZones[2],
      },
      {
        label: "Zone IV stability",
        value: manufacturingDetails.zoneIvStability,
      },
    ];
  };

  return (
    <div className="p14 backgroundColor">
      <BreadCrumbs array={urls} />
      <div className="p-4 bg-white mt-4">
        <div className="ps_main mb-4">
          <h1 className="fw-600">Product Details</h1>
          <FirstButton
            className="borderbtn"
            buttonText="Back to Search Results"
            width="30%"
            onClick={() => navigate("/dossier-search")}
          />
        </div>
        <div className="ps_main">
          <h3 className="fw-600">{data?.title}</h3>
          <p className="fs-14 fw-600 mb-0">Code-{data?.code}</p>
          <FirstButton
            className="btn"
            buttonText="Request Term Sheet"
            width="30%"
            onClick={() => navigate(`/product-term-sheet/${id}`)}
          />
        </div>
      </div>
      <div>
        <hr />
        <div className="dfcg3">
          <div className="flex1 ">
            <h2 className="my-2  heading-bg-color text-white">
              Product/ Dossier details
            </h2>
            <table class="table table-striped bx-shadow">
              <tbody>
                {productdetails &&
                  productdetails.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div
              className="my-2 bx-shadow bg-white"
              style={{ padding: "2rem" }}
            >
              <p className="fs-16 lh-base">
                This is a curated dossier which means at PharmaDigm, we have
                done a due diligence and the report can be made available
              </p>
              <p className="fs-16 lh-base">
                The seller is a large generic company head quartered in India
                and originally developed the product for own commercialisation.
                Due to a change of strategy, they are now looking for a
                licensing partner.
              </p>
              <p className="fs-16 lh-base">
                The seller will support the submission and queries from own
                facility which has all regulated market GMPs. Post approval, the
                buyer is required to tech transfer to an alternate facility.
                PharmaDigm can identify a CDMO with a similar manufacturing
                equipment for ease of tech transfer.
              </p>
              <p className="fs-16 lh-base">
                The seller's dossier can be used for other reg markets due to
                similarities in ref product. BE for Ex-US markets will have to
                be performed by Buyer at his own cost. PharmaDigm has a list of
                CROs with prior experience of the molecule.
              </p>
              <p className="fs-16 lh-base">
                Dossier due diligence can be arranged after initial commercials
                are agreed and upon signing of a term sheet.
              </p>
              <p className="fs-16 lh-base">
                Acoording to PharmaDigm, Dexlansoprazole has sales of $ mn in
                the US, Y mn in EU and there are no generics as on date
              </p>
            </div>
          </div>
          <div className="flex1">
            <h2 className="my-2  heading-bg-color text-white">Deal Contours</h2>
            <table class="table table-striped bx-shadow">
              <tbody>
                {dealCounter &&
                  dealCounter.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <h2 className="my-2  heading-bg-color text-white">
              Manufacturing Details
            </h2>
            <table class="table table-striped bx-shadow">
              <tbody>
                {manufDetails &&
                  manufDetails.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <FirstButton
              className="btn my-2"
              buttonText="Request Term Sheet"
              width="100%"
              onClick={() => navigate(`/product-term-sheet/${id}`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
