import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "../navigation/Header";
// import SideNav from "../navigation/SideNav";
const ProtectedRoute = ({ allowedRoles }) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  return token && allowedRoles.includes(role) ? (
    <>
      <Header />
      <div className="only-d-flex " style={{ minHeight: "95vh" }}>
        {/* {role === "admin" ? <SideNav /> : <></>} */}

        <div className=" w-100 bg_color">
          <Outlet />
          {/* <Footer /> */}
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoute;
