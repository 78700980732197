import React, { useEffect } from "react";
import News from "../components/Home/News";
import ExploreMain from "../components/Home/ExploreMain";
import ShowCards from "../components/Home/ShowCards";
import MarketPlaceMain from "../components/Home/MarketPlaceMain";

const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <>
      <ExploreMain />
      <ShowCards />
      <MarketPlaceMain />
      <News />
    </>
  );
};

export default Landing;
