import React, { useState, useEffect } from "react";
import FirstButton from "../common/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import login from "../assets/images/login-1.jpeg";
const ResetPassword = (props) => {
  let { key, emailId } = useParams();
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const handleChange = (event) => {
    // const name=event.tareget.name
    const value = event.target.value;
    const name = event.target.name;
    if (name === "password") {
      setPassword(value);
    } else if (name === "conPassword") {
      setConPassword(value);
    }
  };
  const handleSubmit = () => {
    const data = {
      Email: emailId,
      validateStr: key,
      password: password,
    };

    if (password?.trim()?.length < 8) {
      toast.error("Password Length should be greater then 8");
      return;
    }
    if (password !== conPassword) {
      toast.error("Password and Conform Password should be same");
      return;
    }

    axios
      .post(process.env.REACT_APP_BACKEND + "reset-password", data)
      .then((resp) => {
        toast.success(resp && resp.data && resp.data.message);
        setPassword("");
        setConPassword("");
        navigate("/login");
      })
      .catch((err) => {
        toast.error(
          err && err.response && err.response.data && err.response.data.message
        );
      });
  };
  return (
    <div className="login_main">
      <div
        className="only-d-flex about-class justify-between"
        style={{ height: "90vh" }}
      >
        <div
          className="w50 px-4 py-4 login-card-color text-white image-display-none"
          style={{
            backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0) 80%), url(${login})`,
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="login_heading text-white mb-4">Reset Password</div>
            <p className="fs-20 fw-700 mb-4">
              Enter your new password for {emailId}.
            </p>
          </div>
        </div>
        <div
          className="w50 px-4 py-4 "
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="reg_body">
            <div className="mt-4">
              <p className="login_para  ">
                <label> Password</label>
              </p>
              <div>
                <input
                  className="login_input  "
                  value={password}
                  name="password"
                  type="password"
                  onChange={handleChange}
                  placeholder="Enter Your Password"
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="mt-4">
              <p className="login_para  ">
                <label> Confirm Password</label>
              </p>
              <div>
                <input
                  className="login_input  "
                  value={conPassword}
                  name="conPassword"
                  type="password"
                  onChange={handleChange}
                  placeholder="Enter Your Confirm Password"
                  id="outlined-basic"
                  label="Confirm Password"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="mt-5">
              <FirstButton
                className="btn "
                buttonText="Submit"
                onClick={handleSubmit}
                width="40%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
