import React, { useState, useEffect } from "react";
import FirstButton from "../common/button";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../Auth/AuthContext";
import loginImg from "../assets/images/login-1.jpeg";
const Login = (props) => {
  const { login } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userrole, setUserRole] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const handleSubmit = () => {
    const data = {
      email: username,
      password: password,
    };
    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      username?.trim()?.length === 0 ||
      !username?.trim()?.match(mailFormat)
    ) {
      toast.error("Email format is invalid");
      return;
    }
    if (password?.trim()?.length === 0 || password?.trim()?.length < 8) {
      toast.error("Please enter a valid password");
      return;
    }

    axios
      .post(process.env.REACT_APP_BACKEND + "login", data)
      .then((resp) => {
        toast.success(resp && resp.data && resp.data.message);

        let token = resp?.data?.token;
        let role = resp?.data?.role;
        let name = resp?.data?.name;
        login(role, token, name);
        setUsername("");
        setPassword("");
        navigate("/home");
      })
      .catch((err) => {
        toast.error(
          err && err.response && err.response.data && err.response.data.message
        );
      });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };
  return (
    <div className="login_main">
      <div
        className="only-d-flex about-class justify-between"
        style={{ height: "90vh" }}
      >
        <div
          className="w50 px-4 py-4 login-card-color text-white image-display-none"
          style={{
            backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0) 80%), url(${loginImg})`,
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="login_heading text-white mb-4">
              Hi! Welcome back!
            </div>
            <p className="fs-20 fw-600 mb-4 mx-4">
              Please sign in to continue accessing our platform.
              <br /> We’re here to facilitate your licensing and partnership
              opportunities
            </p>
            {/* <div className="m-auto text-center ">
              <p className="fs-14 fw-500 ">
                Up-to-date Dossiers for Global markets
              </p>
              <p className="fs-14 fw-500">
                Already curated information on multiple dosage forms
              </p>
              <p className="fs-14 fw-500">
                Plethora of suppliers to choose from
              </p>
              <p className="fs-14 fw-500">
                
                Additional services to complete the picture
              </p>
            </div> */}
          </div>
        </div>

        <div
          className="w50 px-4 py-4"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="login_body">
            <p className="login_para ">
              <label> Email ID</label>
            </p>
            <input
              className="login_input mb-4  "
              value={username}
              name="username"
              onChange={handleChange}
              placeholder="Enter Your Email Id"
              id="outlined-basic"
              label="Email ID"
              variant="outlined"
            />

            <p className="login_para">
              <label htmlFor=""> Password</label>
            </p>
            <input
              className="login_input mb-2 "
              type="password"
              name="password"
              onChange={handleChange}
              placeholder="Enter Your Password"
              value={password}
              id="outlined-basic"
              label="Password"
              variant="outlined"
            />
            <div className="d-flex aligin-items-center justify-content-between">
              <p className="fs-14 mt-4">
                <Link to="/forgot" className=" login_link">
                  Forgot password?
                </Link>
              </p>
              <div className="d-flex aligin-items-center justify-content-between ms-2">
                <input type="checkbox" name="remember" />
                <p className="fs-12 fw-500 ms-1 mb-0">Remember me</p>
              </div>
            </div>
            <FirstButton
              className="btn "
              buttonText="Login"
              marginTop="2rem"
              width="40%"
              onClick={handleSubmit}
            />
            <p className="login_last_para">
              Don't have Account ? &nbsp;&nbsp;
              <span>
                <Link to="/sign-up" className="login_link">
                  Signup
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
