import React, { createContext, useState, useContext } from "react";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);

  const login = (role, token, name) => {
    // Implement login logic here (e.g., API request)
    setToken(token);
    setUser(name);
    setRole(role);
    localStorage.setItem("token", token);
    localStorage.setItem("name", name);
    localStorage.setItem("role", role);
  };

  const signup = (userData) => {
    // Implement signup logic here (e.g., API request)
    setUser(userData);
  };

  const logout = () => {
    // Implement logout logic here (e.g., clear user data)
    setUser(null);
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("role");

    localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ token, user, role, login, signup, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
