import React, { useEffect, useState, useRef } from "react";
import doc from "../assets/Mutual CDA format- Pharmadigm.docx";
import FirstButton from "../common/button";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbs from "./BreadCrumbs";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import axios from "axios";
const SupplierTermSheet = () => {
  const urls = [
    { name: "Home", url: "/home" },
    { name: "Supplier Search", url: "/supplier-search" },
    { name: "Supplier Term Sheet", url: "" },
  ];
  const navigate = useNavigate();
  let { id } = useParams();
  const [data, setData] = useState([]);
  const [downloadCda, setDownloadCda] = useState(true);
  const [downloadExcel, setDownloadExcel] = useState(true);
  const fileInputRef = useRef(null);
  const fileInputRefCDA = useRef(null);
  const [checked, setChecked] = useState(false);
  const [Dosageformsofinterest, SetDosageformsofinterest] = useState("");
  const [IndicativeVolumes, SetIndicativeVolumes] = useState("");
  const [GMPstatusrequired, setGMPstatusrequired] = useState("");
  const [Specialcapabilities, setSpecialcapabilities] = useState("");
  const [cdaFile, setCdAFile] = useState("");
  const [rfqFile, setRFQ] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
    getSupplierDetails();
  }, []);

  const getSupplierDetails = () => {
    const data = { id: id };
    axios
      .post(process.env.REACT_APP_BACKEND + "get-supplierbyid", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((resp) => {
        setData(resp?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDownloadClick = () => {
    if (downloadExcel) {
      setDownloadExcel(!downloadExcel);
      // Dummy Excel data (replace with your actual Excel data)
      const dummyExcelData =
        "data:text/csv;charset=utf-8," + encodeURIComponent("Dummy Excel Data");

      // Create a blob URL for the Excel data
      const blob = new Blob([dummyExcelData], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      // Create an anchor element with the download attribute
      const a = document.createElement("a");
      a.href = url;
      a.download = "dummy_excel_sheet.csv";

      // Programmatically trigger the click event on the anchor element
      a.click();

      // Clean up by revoking the blob URL
      URL.revokeObjectURL(url);
    } else {
      fileInputRef.current.click();
    }
  };

  const handleDownloadCDA = () => {
    if (downloadCda) {
      // Change the state if needed or handle any other logic
      setDownloadCda(!downloadCda);

      // Trigger the download of the document
      const link = document.createElement("a");
      link.href = process.env.PUBLIC_URL + doc; // Adjust the path to your Word document
      link.download = "CDA draft.docx"; // The name for the downloaded file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      fileInputRefCDA.current.click();
    }
  };
  const handleFileChange = (event) => {
    // const files = event.target.files;
    const files = event.target.files[0];
    const name = event.target.name;
    if (name === "cda") {
      setCdAFile(files);
    } else if (name === "RFQ") {
      setRFQ(files);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "Dosageformsofinterest") {
      SetDosageformsofinterest(value);
    } else if (name === "IndicativeVolumes") {
      SetIndicativeVolumes(value);
    } else if (name === "GMPstatusrequired") {
      setGMPstatusrequired(value);
    } else if (name === "Specialcapabilities") {
      setSpecialcapabilities(value);
    }
  };

  const submitTermSheet = () => {
    if (GMPstatusrequired?.trim().length === 0) {
      toast.error("Enter GMP Status ");
      return;
    }
    if (Dosageformsofinterest?.trim().length === 0) {
      toast.error("Enter Dosage forms of interest ");
      return;
    }
    if (IndicativeVolumes?.trim().length === 0) {
      toast.error("Please enter Indicative Volumes");
      return;
    }
    if (Specialcapabilities?.trim().length === 0) {
      toast.error("Please enter Special capabilities ");
      return;
    }

    if (cdaFile === "") {
      toast.error("Please select the CDA file");
      return;
    }

    if (rfqFile === "") {
      toast.error("Please select the RFQ file");
      return;
    }
    if (!checked) {
      toast.error("Please Agree the Terms and Condition  ");
      return;
    }

    let formData = new FormData();
    formData.append("gmpStatusRequired", GMPstatusrequired);
    formData.append("dosageFormsOfInterest", Dosageformsofinterest);
    formData.append("id", id);
    formData.append("indicativeVolumes", IndicativeVolumes);
    formData.append("specialCapabilitiesRequired", Specialcapabilities);
    formData.append("cdaOrNda", cdaFile);
    formData.append("rfq", rfqFile);

    axios
      .post(
        process.env.REACT_APP_BACKEND + "create-suppliertermsheet",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((resp) => {
        toast.success(resp && resp.data && resp.data.msg);

        navigate("/supplier-search");
      })
      .catch((err) => {
        toast.error(
          err && err.response && err.response.data && err.response.data.msg
        );
      });
  };
  return (
    <div
      className="p14 backgroundColor"
      style={{ paddingBottom: "2rem", margin: "auto" }}
    >
      <BreadCrumbs array={urls} />

      <div style={{ margin: "2rem auto" }}>
        <div className="shadow px-5 py-5 mb-5 bg-white rounded">
          <h1 className="mb2 about_heading">Request a Term Sheet</h1>
          <p className="my-2 fs-16 lh-base  ">
            Get a unique pricing proposal tailored to your needs.
          </p>
          <p className="fs-14 fw-500 mb-4 ">
            Thank you for showing interest in
            <span className="fw-600">
              {" "}
              {data?.title} {data?.code}{" "}
            </span>{" "}
            . Please enter the following details for us to provide a proposa
          </p>

          <div className="row justify-content-between">
            <div className="col-md-7">
              <div className="row mb-4">
                <div className="col-3">
                  <p className="fs-16 fw-400 text-start mb-0">
                    GMP status required
                  </p>
                </div>
                <div className="col-8">
                  <input
                    className="login_input "
                    value={GMPstatusrequired}
                    name="GMPstatusrequired"
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Markets of interest"
                    variant="outlined"
                    required
                  />
                  <p className="fs-12 fw-400 text-start mb-0 mt-2">
                    Enter as many accreditations required, separated by commas
                  </p>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-3">
                  <p className="fs-16 fw-400 text-start mb-0">
                    Dosage forms of interest
                  </p>
                </div>
                <div className="col-8">
                  <input
                    className="login_input "
                    value={Dosageformsofinterest}
                    name="Dosageformsofinterest"
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Markets of interest"
                    variant="outlined"
                    required
                  />
                  <p className="fs-12 fw-400 text-start mb-0 mt-2">
                    Enter as many dosage forms as required, separated by commas
                  </p>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-3">
                  <p className="fs-16 fw-400 text-start mb-0">
                    Indicative Volumes
                  </p>
                </div>
                <div className="col-8">
                  <input
                    className="login_input "
                    value={IndicativeVolumes}
                    name="IndicativeVolumes"
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Indicative Volumes"
                    variant="outlined"
                    required
                  />
                  <p className="fs-10 fw-400 text-start mb-0 mt-2">
                    In single units/ pills/ vials/ tubes etc.
                  </p>
                  <p className="fs-12 fw-400 text-start mb-0 mt-2">
                    per dosage form, separated by comma
                  </p>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-3">
                  <p className="fs-16 fw-400 text-start mb-0">
                    Special capabilities required
                  </p>
                </div>
                <div className="col-8">
                  <input
                    className="login_input "
                    value={Specialcapabilities}
                    name="Specialcapabilities"
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Special capabilities"
                    variant="outlined"
                    required
                  />
                </div>
              </div>
              <p className="fs-14 fw-500 mt-3">
                The information you provide will be kept confidential in
                accordance with our Privacy Policy
              </p>
              <div className="d-flex">
                <Checkbox
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <p className="fs-14 fw-500 ms-3 mb-0">
                  I agree to PharmaDigm suggesting other suppliers that meet my
                  criteria.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="border py-3" style={{ borderRadius: "8px" }}>
                <p className="fs-14 fw-600 text-center mb-2">CDA/ NDA</p>
                <p className="fs-14 fw-500 text-center mb-2">
                  Feel more comfortable with a CDA/ NDA?
                </p>
                <div className="m-auto text-center">
                  <FirstButton
                    className="btn"
                    buttonText={
                      downloadCda ? "Download draft here." : "Upload filled CDA"
                    }
                    width="60%"
                    onClick={handleDownloadCDA}
                  />
                  <input
                    type="file"
                    ref={fileInputRefCDA}
                    style={{ display: "none" }}
                    name="cda"
                    onChange={handleFileChange}
                    accept=".docx, .pdf"
                    multiple // Allows multiple file selection if needed
                  />
                  <p
                    className="fs-10 fw-500 mt-2 text-info"
                    style={{ cursor: "pointer" }}
                    onClick={() => setDownloadCda((prevState) => !prevState)}
                  >
                    {downloadCda
                      ? "Upload filled excel"
                      : "Download excel draft"}
                  </p>
                </div>
              </div>

              <div className="border py-3 mt-4" style={{ borderRadius: "8px" }}>
                <p className="fs-14 fw-600 text-center mb-2">RFQ</p>
                <p className="fs-14 fw-500 text-center mb-2">
                  Feel more comfortable excel format?
                </p>
                <div className="m-auto text-center">
                  <FirstButton
                    className="btn"
                    buttonText={
                      downloadExcel
                        ? "Download excel draft"
                        : "Upload filled excel"
                    }
                    width="60%"
                    onClick={handleDownloadClick}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    name="RFQ"
                    onChange={handleFileChange}
                    accept=".docx, .pdf"
                    multiple // Allows multiple file selection if needed
                  />
                  <p
                    className="fs-10 fw-500 mt-2 text-info"
                    style={{ cursor: "pointer" }}
                    onClick={() => setDownloadExcel((prevState) => !prevState)}
                  >
                    {downloadExcel
                      ? "Upload filled excel"
                      : "Download excel draft"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <FirstButton
              className="borderbtn"
              buttonText="Cancel"
              onClick={() => navigate("/supplier-search")}
            />
            <FirstButton
              buttonText="Submit"
              className="enquire-btn ms-4"
              onClick={submitTermSheet}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierTermSheet;
