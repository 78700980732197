import React from "react";
import { useNavigate } from "react-router-dom";
import img from "../../assets/images/icons/landing.png";
import FirstButton from "../../common/button";
import { role } from "../../utils/index";
import table from "../../assets/images/tablet.png";
import img2 from "../../assets/images/landling.jpeg";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import VaccinesIcon from "@mui/icons-material/Vaccines";
const ExploreMain = () => {
  const navigate = useNavigate();
  const userrole = localStorage.getItem("role");
  const handleNavigate = () => {
    if (userrole === "investor") {
      navigate("/investment/activedeals");
      window.location.reload();
    } else {
      navigate("/login");
    }
  };

  return (
    <div
      className="main"
      style={{ backgroundColor: "#ebebeb " }}
      // style={{
      //   backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.9) 40%, rgba(0, 0, 0, 0) 65%), url(${img2})`,
      //   backgroundSize: "contain",
      //   backgroundPosition: "center",
      //   backgroundColor: "#EBEBEB ",
      // }}
    >
      <div className="d-Flex justify-content-between">
        <div className="col-md-8 col-sm-12">
          <div className="main_text">
            <h1 className="primaryText  text-start main-text-color">
              PharmaDigm
            </h1>
            <h1 className="primaryText dark text-start">
              The B2B marketplace for pharmaceuticals
            </h1>
          </div>
          <div className="only-d-flex mt-5">
            <div>
              <p className="sub-head">Generics</p>
              <VaccinesIcon
                style={{ marginBottom: "16px" }}
                class="wh-8 lightgreen"
              />
            </div>
            <div>
              <p className="sub-head">Dossiers</p>
              <FileCopyOutlinedIcon
                style={{ marginBottom: "16px" }}
                class="wh-8 lightgreen"
              />
            </div>
            <div>
              <p className="sub-head">Licensing</p>
              <WorkspacePremiumOutlinedIcon
                style={{ marginBottom: "16px" }}
                class="wh-8 lightgreen"
              />
            </div>
            <div>
              <p className="sub-head">CDMO</p>
              <PeopleAltIcon
                style={{ marginBottom: "16px" }}
                class="wh-8 lightgreen"
              />
            </div>
          </div>
          <div className="mt-3">
            {userrole === role[1].role ? (
              <FirstButton
                className="btn"
                float="left"
                marginTop="5rem"
                buttonText="Get Started Now"
                onClick={handleNavigate}
              />
            ) : (
              <div className="land_btn_cont">
                <FirstButton
                  buttonText="HOW IT WORKS"
                  className="btn "
                  float="left"
                  marginTop="5rem"
                  padding="1rem"
                  onClick={() => navigate("/how-it-works")}
                />
                <FirstButton
                  buttonText="GET STARTED"
                  className="btn "
                  float="left"
                  marginTop="5rem"
                  onClick={() =>
                    localStorage?.getItem("token")
                      ? navigate("/home")
                      : navigate("/sign-up")
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-md-4 col-sm-12 mt-4">
          <div style={{ textAlign: "center" }}>
            <img src={img} alt="" className="main_img1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreMain;
