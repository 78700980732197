import React, { useState, useEffect } from "react";
import FirstButton from "../common/button";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import login from "../assets/images/login-1.jpeg";
const SignUp = (props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const handleChange = (event) => {
    // const name=event.tareget.name
    const value = event.target.value;
    const name = event.target.name;
    if (name === "email") {
      setEmail(value);
    } else if (name === "name") {
      setName(value);
    }
  };
  const handleSubmit = () => {
    const data = {
      Email: email,
      name: name,
    };
    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (name?.trim()?.length === 0) {
      toast.error(" Name is required");
      return;
    }
    if (email?.trim()?.length === 0 || !email?.trim()?.match(mailFormat)) {
      toast.error("Email format is invalid");
      return;
    }

    axios
      .post(process.env.REACT_APP_BACKEND + "sign-up", data)
      .then((resp) => {
        toast.success(resp && resp.data && resp.data.message);
        setEmail("");
        setName("");
      })
      .catch((err) => {
        toast.error(
          err && err.response && err.response.data && err.response.data.message
        );
      });
  };
  return (
    <div className="login_main">
      <div
        className="only-d-flex about-class justify-between"
        style={{ height: "90vh" }}
      >
        <div
          className="w50 px-4 py-4 login-card-color text-white image-display-none"
          style={{
            backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0) 80%), url(${login})`,
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="login_heading text-white mb-4">Sign Up</div>
            <p className="fs-20 fw-700 mb-4">
              Join our marketplace & unlock benefits!
            </p>
            <div className="m-auto text-center ">
              <p className="fs-16 fw-500 ">
                Up-to-date Dossiers for Global markets
              </p>
              <p className="fs-16 fw-500">
                Curated information on multiple dosage forms
              </p>
              <p className="fs-16 fw-500">
                Plethora of suppliers to choose from
              </p>
              <p className="fs-16 fw-500">
                Additional services to complete the picture
              </p>
            </div>
          </div>
        </div>
        <div
          className="w50 px-4 py-4 "
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="reg_body">
            <div className="fs-20 fw-600 ">
              Provide your business email below and we will send you an email to
              get started.
            </div>
            <div className="mt-4">
              <p className="login_para  ">
                <label> Name</label>
              </p>
              <div>
                <input
                  className="login_input  "
                  value={name}
                  name="name"
                  onChange={handleChange}
                  placeholder="Enter Your Name"
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="mt-4">
              <p className="login_para  ">
                <label> Email ID</label>
              </p>
              <div>
                <input
                  className="login_input  "
                  value={email}
                  name="email"
                  onChange={handleChange}
                  placeholder="Enter Your Email Id"
                  id="outlined-basic"
                  label="Email ID"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="mt-5">
              <FirstButton
                className="btn "
                buttonText="Sign Up"
                onClick={handleSubmit}
                width="40%"
              />
              <p className="login_last_para">
                Already registered ?
                <span>
                  <Link to="/login" className="login_link">
                    Login
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
