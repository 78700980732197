import React, { useState, useEffect } from "react";
import FirstButton from "../common/button";
import { Link, useNavigate } from "react-router-dom";

import welcome2 from "../assets/images/addedNew/wel-come.jpg.png";
const Welcome = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const handleSubmit = () => {
    // roles(username)
    // if (username && password) {
    navigate("/home");
    // }
  };

  return (
    <div className="welcome_main">
      <div className="only-d-flex about-class  justify-between pe-4">
        <div className="w50">
          <img
            className="bg-auth-circle-shape"
            src={welcome2}
            style={{ width: "100%" }}
          />
        </div>
        <div className="w50 px-4">
          <h2
            className="dark text-start fw-600 mb-4"
            style={{ fontSize: "30px", marginTop: "10rem" }}
          >
            <span className="text-info"> Thank you for joining us ! </span>
            You're now part of our global network. Begin exploring opportunities
            that can elevate your business to the next level!
          </h2>
          <div className="d-flex ">
            <FirstButton
              className="btn "
              buttonText="Get started"
              marginTop="2rem"
              padding="10px"
              width="55%"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
    // <>
    //
    //   <div
    //     className="p6"
    //     style={{
    //       backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.9) 40%, rgba(0, 0, 0, 0) 65%), url(${welcome2})`,
    //       backgroundSize: "cover",
    //       backgroundPosition: "center",
    //       backgroundColor: "#EBEBEB ",
    //       height: "80vh",
    //     }}
    //   >
    //     <div className="w40 " style={{ marginTop: "130px" }}>
    //       <h2
    //         className="dark text-start fw-600 mb-4"
    //         style={{ fontSize: "30px" }}
    //       >
    //         <span className="text-info"> Thank you for joining us ! </span>
    //         You're now part of our global network. Begin exploring opportunities
    //         that can elevate your business to the next level!
    //       </h2>
    //     </div>
    //     <div className=" main_container1">
    //       <div className="w100">
    //         <FirstButton
    //           className="btn "
    //           buttonText="Get started"
    //           marginTop="2rem"
    //           padding="10px"
    //           width="30%"
    //           onClick={handleSubmit}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
};

export default Welcome;
