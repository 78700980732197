import React, { useState, useEffect } from "react";
import FirstButton from "../common/button";
import { Link, useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { DialogContent, Dialog, IconButton } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import login from "../assets/images/login-1.jpeg";
const Forgot = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const handleSubmit = () => {
    const data = {
      Email: email,
    };
    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email?.trim()?.length === 0 || !email?.trim()?.match(mailFormat)) {
      toast.error("Email format is invalid");
      return;
    }

    axios
      .post(process.env.REACT_APP_BACKEND + "forgot-password", data)
      .then((resp) => {
        toast.success(resp && resp.data && resp.data.message);
        setEmail("");
        setOpen(true);
      })
      .catch((err) => {
        toast.error(
          err && err.response && err.response.data && err.response.data.message
        );
      });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "email") {
      setEmail(value);
    }
  };
  return (
    <div className="login_main ">
      <div className="login_heading">Forgot Password!</div>
      <div className="login_body">
        {/* <Divider className="login_divider">or Login with</Divider> */}

        <p className="login_para ">
          <label> Email ID</label>
        </p>
        <input
          className="login_input mb-4 "
          value={email}
          name="email"
          onChange={handleChange}
          placeholder="Enter Your Email Id"
          id="outlined-basic"
          label="Email ID"
          variant="outlined"
        />

        <FirstButton
          className="btn "
          buttonText="Submit"
          marginTop="2rem"
          width="40%"
          onClick={handleSubmit}
        />
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        open={open}
      >
        <div className="d-flex justify-content-end align-items-center p-3">
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <DialogContent dividers>
          <div className="px-4 py-2 text-center">
            {/* <img alt="" src={thankyou} /> */}
            <h4 className="fs-20 fw-700">Link send to respective Mail ID </h4>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Forgot;
