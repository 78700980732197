import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FirstButton from "../common/button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  filters,
  // searchResult,
  about,
  list,
  list2,
  list3,
  list4,
  list5,
} from "../data/SearchData";
import moment from "moment/moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Card, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import BreadCrumbs from "./BreadCrumbs";
import { Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import Dropdown from "../common/dropdown";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import noData from "../assets/images/noData.png";
const ProductSearchPage = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("1");
  const [searchTerms, setSearchTerms] = useState({});
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [search, setSearch] = useState("");
  const [count, setCount] = useState("");

  const initialState = {
    Molecule: {
      ATCClass: null,
      DosageForm: null,
      RouteOfAdministration: null,
    },
    dates: {
      Before: "",
      After: "",
    },
    checkboxes: {
      CountryofmanufactureAsia: false,
      CountryofmanufactureEurope: false,
      CountryofmanufactureNorthAmerica: false,
      CountryofmanufactureRestoftheWorld: false,
      CuratedCurateddossiersonly: false,
      DossierreadinessEarlydevelopment: false,
      DossierreadinessLatestage: false,
      DossierreadinessReadyEU: false,
      DossierreadinessReadyUS: false,
      RegionofInterestAsia: false,
      RegionofInterestEurope: false,
      RegionofInterestNorthAmerica: false,
      RegionofInterestRestoftheWorld: false,
      SupplyTechtransferonly: false,
      SupplyWithsupplyonly: false,
    },
  };

  const [formState, setFormState] = useState(initialState);
  const [searchResult, setSearchResult] = useState([]);
  const [checkformState, setCheckFormState] = useState(initialState);
  const urls = [
    { name: "Home", url: "/home" },
    { name: "Dossier Search", url: "" },
  ];
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
    getDossier(1, "");
  }, []);

  const getDossier = (page, search) => {
    const data = {
      page: page,
      limit: 3,
      searchChar: search,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "get-dossier", data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((resp) => {
        setSearchResult(resp?.data?.data);
        const totalObjects = resp?.data?.totalcount;
        setCount(totalObjects);
        const totalPages = Math.ceil(totalObjects / 3);
        setTotalPage(totalPages);
        setPage(page);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = () => {
    getDossier(1, search);
  };
  const handleChange = (event, newValue) => {
    if (newValue === "1") {
      navigate("/dossier-search");
    } else if (newValue === "2") {
      navigate("/supplier-search");
    }
    setValue(newValue);
  };
  const handleSearchChange = (filterId, e) => {
    setSearchTerms({
      ...searchTerms,
      [filterId]: e.target.value.toLowerCase(),
    });
  };

  const handleDropdownChange = (key, value) => {
    const updatedState = {
      ...formState,
      Molecule: {
        ...formState.Molecule,
        [key]: value,
      },
    };
    setFormState(updatedState);

    searchDossier(updatedState); // Pass updated state to searchDossier
  };

  const handleDateChange = (name, value) => {
    const updatedState = {
      ...formState,
      dates: {
        ...formState.dates,
        [name]: value,
      },
    };
    setFormState(updatedState);

    searchDossier(updatedState); // Pass updated state to searchDossier
  };

  const normalizeKey = (category, name) => {
    return `${category}${name}`.replace(/[\s-]/g, "");
  };

  const handleCheckboxChange = (category, name, checked) => {
    const keyName = normalizeKey(category, name);
    const updatedState = {
      ...formState,
      checkboxes: {
        ...formState.checkboxes,
        [keyName]: checked,
      },
    };
    setFormState(updatedState);

    searchDossier(updatedState); // Pass updated state to searchDossier
  };
  const searchDossier = (updatedState, pageNo) => {
    const data = {
      page:
        pageNo === "" || pageNo === null || pageNo === undefined ? 1 : pageNo,
      limit: 3,
      searchChar: search,
      therapCategory:
        updatedState?.Molecule?.ATCClass !== null
          ? updatedState?.Molecule?.ATCClass?.label
          : "",
      doseForm:
        updatedState?.Molecule?.DosageForm !== null
          ? updatedState?.Molecule?.DosageForm?.label
          : "",
      routeOfAdministration:
        updatedState.Molecule?.RouteOfAdministration !== null
          ? updatedState?.Molecule?.RouteOfAdministration?.label
          : "",
      usConstraintBeforDate: updatedState?.dates?.Before,
      usConstraintAfterDate: updatedState?.dates?.After,
      RegionofInterest: {
        Asia: updatedState?.checkboxes?.RegionofInterestAsia,
        Europe: updatedState?.checkboxes?.RegionofInterestEurope,
        NorthAmerica: updatedState?.checkboxes?.RegionofInterestNorthAmerica,
        RestoftheWorld:
          updatedState?.checkboxes?.RegionofInterestRestoftheWorld,
      },
      countryOfManufacture: {
        Asia: updatedState?.checkboxes?.CountryofmanufactureAsia,
        Europe: updatedState?.checkboxes?.CountryofmanufactureEurope,
        NorthAmerica:
          updatedState?.checkboxes?.CountryofmanufactureNorthAmerica,
        RestoftheWorld:
          updatedState?.checkboxes?.CountryofmanufactureRestoftheWorld,
      },
      dossierStatus: {
        ReadyUS: updatedState?.checkboxes.DossierreadinessReadyUS,
        ReadyEU: updatedState?.checkboxes.DossierreadinessReadyEU,
        EarlyDevelopment:
          updatedState?.checkboxes.DossierreadinessEarlydevelopment,
        LateStage: updatedState?.checkboxes.DossierreadinessLatestage,
      },
      curated: updatedState?.checkboxes?.CuratedCurateddossiersonly,
      supplyStatus: {
        WithSupplyOnly: updatedState?.checkboxes?.SupplyWithsupplyonly,
        TechTransferOnly: updatedState?.checkboxes?.SupplyTechtransferonly,
      },
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "search-dossier", data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((resp) => {
        setSearchResult(resp?.data?.data);
        const totalObjects = resp?.data?.totalCount;
        setCount(totalObjects);
        const totalPages = Math.ceil(totalObjects / 3);
        setTotalPage(totalPages);
        if (pageNo === "" || pageNo === null || pageNo === undefined) {
          setPage(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);

    if (formState === checkformState) {
      getDossier(value, "");
    } else {
      searchDossier(formState, value);
    }
  };

  return (
    <div className="search backgroundColor">
      <BreadCrumbs array={urls} />

      <div className="only-d-flex justify-center align-center mt-3">
        <Tabs
          value={value}
          onChange={handleChange}
          className="w30 "
          sx={{
            "& button": { borderRadius: 2 },
            "& button:hover": {
              backgroundColor: "#7915cf",
              color: "white",
              opacity: 0.55,
            },
            "& button:focus": {
              backgroundColor: "#7915cf0",
              color: "white",
            },
            "& button:active": {
              backgroundColor: "#7915cf",
              color: "white",
            },
            "& .MuiTabs-indicator": {
              display: "none", // Hide the indicator line
            },
          }}
        >
          <Tab
            className={
              value === "1" ? "fs-14 fw-600  activeTab" : "fs-14 fw-600 "
            }
            value="1"
            label="Dossier Finder"
          />
          <Tab
            className={
              value === "2" ? "fs-14 fw-600  activeTab" : "fs-14 fw-600 "
            }
            value="2"
            label="Supplier Finder"
          />
        </Tabs>
      </div>
      <div className="searchInput">
        <input
          className="login_input bg-white"
          placeholder="Search"
          style={{ width: "60%" }}
          value={search}
          onChange={(e) => {
            const value = e.target.value;
            setSearch(value);
            if (value.length === 0) {
              getDossier(1, "");
            }
          }}
        />

        <FirstButton
          buttonText="Submit"
          onClick={handleSubmit}
          className="btn ms-2"
        />
      </div>
      <div className="dflex ">
        <div className="w20">
          <h2 className="main_imgcont2 fs-16 my-2">Filters</h2>
        </div>
        <div className="w60">
          <h2 className="main_imgcont2 fs-16 my-2 ">{count} dossiers found</h2>
        </div>
      </div>
      <div className="dflex ">
        <div className="w20 m121">
          {filters &&
            filters.map((item, index) => (
              <Accordion
                key={index}
                // expanded={expandedAccordion === index} // Only expand if the current accordion matches the state
                // onChange={() => handleAccordionChange(index)} // Handle accordion change
              >
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <h4>{item.name}</h4>
                </AccordionSummary>
                <AccordionDetails className="px-3 py-2 text-start">
                  {item.subFilters && item.id !== 1 && item.id !== 3 && (
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      fullWidth
                      value={searchTerms[item.id] || ""}
                      onChange={(e) => handleSearchChange(item.id, e)}
                      style={{ marginBottom: "1rem" }}
                    />
                  )}

                  {item.subFilters &&
                    item.subFilters
                      .filter(
                        (itemSub) =>
                          !searchTerms[item.id] ||
                          itemSub.toLowerCase().includes(searchTerms[item.id])
                      )
                      .map((itemSub, subIndex) => (
                        <AccordionDetails
                          key={subIndex}
                          // className="px-3 py-2"
                          className={
                            item?.id === 1 && subIndex === 2
                              ? "px-3 py-2 mb-40"
                              : "px-3 py-2"
                          }
                        >
                          {item.id === 1 ? (
                            <Autocomplete
                              name="filter"
                              // defaultValue={dValue}
                              options={
                                subIndex === 0 ? (
                                  list
                                ) : subIndex === 1 ? (
                                  list2
                                ) : subIndex === 2 ? (
                                  list3
                                ) : subIndex === 3 ? (
                                  list4
                                ) : subIndex === 4 ? (
                                  list5
                                ) : (
                                  <></>
                                )
                              }
                              className="basic-multi-select"
                              value={
                                subIndex === 0
                                  ? formState?.Molecule?.ATCClass
                                  : subIndex === 1
                                  ? formState?.Molecule?.DosageForm
                                  : subIndex === 2
                                  ? formState?.Molecule?.RouteOfAdministration
                                  : ""
                                // formState.Molecule[`dropdown${subIndex + 1}`]
                              }
                              // classNamePrefix="select"
                              onChange={(event, newValue) => {
                                handleDropdownChange(
                                  subIndex === 0
                                    ? "ATCClass"
                                    : subIndex === 1
                                    ? "DosageForm"
                                    : subIndex === 2
                                    ? "RouteOfAdministration"
                                    : "", // Handle additional cases if needed
                                  newValue
                                );
                              }}
                              // styles={colourStyles}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // variant="outlined"
                                  label={
                                    subIndex === 0
                                      ? "Therapeutic Category"
                                      : subIndex === 1
                                      ? "Dosage Form"
                                      : "Route Of Administration"
                                  }
                                />
                              )}
                            />
                          ) : (
                            <FormControlLabel
                              control={
                                item.id === 3 ? (
                                  <input
                                    type="date"
                                    defaultValue="Small"
                                    className="p-2"
                                    onChange={(e) =>
                                      handleDateChange(itemSub, e.target.value)
                                    }
                                  />
                                ) : (
                                  <Checkbox
                                    // checked={
                                    //   formState.checkboxes[
                                    //     item.name.replace(/[\s,-]+/g, "") +
                                    //       itemSub.replace(/[\s,-]+/g, "")
                                    //   ] || false
                                    // }
                                    checked={
                                      formState.checkboxes[
                                        item.name.replace(/[\s(),-]+/g, "") +
                                          itemSub.replace(/[\s(),-]+/g, "")
                                      ] || false
                                    }
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        item.name.replace(/\s+/g, ""), // Category (e.g., "Dossierreadiness")
                                        itemSub.replace(/\s+/g, ""), // Name (e.g., "ReadyUS")
                                        e.target.checked
                                      )
                                    }
                                    sx={{
                                      color: " #00adfc",
                                      "&.Mui-checked": { color: "#00adfc" },
                                      "& .MuiSvgIcon-root": { fontSize: 24 },
                                    }}
                                  />
                                )
                              }
                              labelPlacement={item.id === 3 ? "start" : "end"}
                              label={
                                <span
                                  style={
                                    item.id === 3 && itemSub === "After"
                                      ? {
                                          fontSize: "1.4rem",
                                          marginRight: "5.2rem",
                                          fontWeight: "400",
                                        }
                                      : item.id === 3
                                      ? {
                                          fontSize: "1.4rem",
                                          marginRight: "4rem",
                                          fontWeight: "400",
                                        }
                                      : item.id === 6
                                      ? {
                                          fontSize: "1.3rem",
                                          marginRight: "0rem",
                                          fontWeight: "400",
                                        }
                                      : {
                                          fontSize: "1.4rem",
                                          marginLeft: "1rem",
                                          fontWeight: "400",
                                        }
                                  }
                                >
                                  {itemSub}
                                </span>
                              }
                            />
                          )}
                        </AccordionDetails>
                      ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
        {count > 0 ? (
          <>
            <div className="w60">
              {searchResult &&
                searchResult.map((sitem, index) => (
                  <div className="ph-spcardmain bg-white" key={sitem?.id}>
                    <div className="ph-cardsphead">
                      <div>
                        <p className="pdcurated ">
                          <span className="mb-1 me-1">
                            <CheckCircleSharpIcon />
                          </span>
                          PD Curated
                        </p>
                        <div className="d-flex">
                          <h2
                            className="fs-24 fw-600 sidebar_close title-hover"
                            onClick={() =>
                              navigate(`/product-details/${sitem?._id}`)
                            }
                          >
                            {sitem?.title}
                          </h2>
                        </div>
                        <p className="ph-cardspp">
                          Delayed release capsules {sitem?.resCapsules}
                        </p>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <FirstButton
                          buttonText="Enquire"
                          className="enquire-btn"
                          onClick={() =>
                            navigate(`/product-term-sheet/${sitem?._id}`)
                          }
                        />
                        <p className="offer-font">
                          Offer in {sitem?.offDay} business days
                        </p>
                      </div>
                    </div>
                    <div className="ph-cardspbody">
                      <div>
                        <p className="ph-cardspp">
                          Available geographies - {sitem?.locations}
                        </p>
                        <p className="ph-cardspp">
                          Dossier status -
                          {[
                            sitem?.dosStatus?.EarlyDevelopment &&
                              "Ready, Early Development BE available",
                            sitem?.dosStatus?.LateStage &&
                              "Ready, LateStage BE available",
                            sitem?.dosStatus?.ReadyEU &&
                              "Late stage, EU BE available",
                            sitem?.dosStatus?.ReadyUS &&
                              "Ready, US BE available",
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </p>
                        <p className="ph-cardspp">
                          Curated - {sitem?.curated ? "Yes" : "No"}
                        </p>
                        <p className="ph-cardspp">
                          Manufacturing GMP - {sitem?.mfg}
                        </p>
                      </div>
                      <div>
                        <p className="ph-cardspp">
                          Country of manufacture -
                          {[
                            sitem?.com?.Asia && "Asia",
                            sitem?.com?.Europe && "Europe",
                            sitem?.com?.NorthAmerica && "North America",
                            sitem?.com?.RestoftheWorld && "Rest of the World",
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </p>
                        <p className="ph-cardspp">
                          US constraint date-
                          {sitem?.usConDate}
                        </p>
                        <p className="ph-cardspp">
                          Supply-
                          {sitem?.supply?.TechTransferOnly
                            ? "Tech Transfer Only"
                            : "With supply only"}
                        </p>
                        <p className="ph-cardspp">Type - {sitem?.type}</p>
                      </div>
                    </div>
                    <div className="mt-2">
                      <h3 className="ph-cardspp">Code:{sitem?.code}</h3>
                      <p className="ph-cardspp lh-base text-justify">
                        {sitem?.descp}
                        <span
                          className="text-info sidebar_close"
                          onClick={() =>
                            navigate(`/product-details/${sitem?._id}`)
                          }
                        >
                          more...
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            <div className="w20">
              {searchResult &&
                searchResult.map((item, index) => (
                  <div className="ph-spcardmain2 bg-white">
                    <h2 className="my-1 fw-600 fs-16">About the molecule</h2>
                    <p className="about-molecule text-justify">{item?.About}</p>
                  </div>
                ))}
            </div>
          </>
        ) : (
          <>
            <Card className="px-2 w100">
              <div className="d-flex flex-column align-items-center p-3">
                <img src={noData} style={{ width: "25%", height: "auto" }} />
                <p className="text-center fs-14 fw-500 light-text-color">
                  No Dossier Found
                </p>
              </div>
            </Card>
          </>
        )}
      </div>
      <div className="d-flex justify-center">
        <Pagination
          count={totalPage}
          page={page}
          defaultPage={1}
          variant="outlined"
          onChange={handlePageChange}
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default ProductSearchPage;
