import React, { useEffect, useState } from "react";
import FirstButton from "../common/button";
import BreadCrumbs from "./BreadCrumbs";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
const SupplierDetails = () => {
  const urls = [
    { name: "Home", url: "/home" },
    { name: "Supplier Search", url: "/supplier-search" },
    { name: "Supplier Details", url: "" },
  ];
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [Capabilities, setCapabilities] = useState([]);
  const [Sptc, setSptc] = useState([]);
  const [manufDetails, setmanufDetails] = useState([]);
  const [commercials, setcommercials] = useState([]);
  let { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
    getSupplierDetails();
  }, []);

  const getSupplierDetails = () => {
    const data = { id: id };
    axios
      .post(process.env.REACT_APP_BACKEND + "get-supplierbyid", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((resp) => {
        setData(resp?.data?.data);
        setCapabilities(convertCapabilities(resp?.data?.data?.capabilities));

        setSptc(
          convertSptc(resp?.data?.data?.specialTechnologiesAndCapabilities)
        );
        setmanufDetails(
          convertManufacturingDetails(resp?.data?.data?.manufacturingDetails)
        );
        setcommercials(convertCommercials(resp?.data?.data?.commercials));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertCapabilities = (capabilities) => {
    return [
      {
        label: "Manufacturing",
        value: capabilities.manufacturing ? "Yes" : "No",
      },
      {
        label: "Dosage forms",
        value: `Tablets ${capabilities.bulkPackaging ? "1 billion +" : ""}`,
      },
      { label: "", value: "Capsules 500 million" }, // Update this based on actual data
      {
        label: "Primary Packaging",
        value: capabilities.primaryPackaging ? "Yes" : "No",
      },
      { label: "Types", value: capabilities.primaryPackagingTypes.join(", ") },
      {
        label: "Secondary Packaging",
        value: capabilities.secondaryPackaging ? "Yes" : "No",
      },
      { label: "", value: capabilities.secondaryPackagingDetails },
      {
        label: "Testing & Release",
        value: capabilities.testingAndRelease ? "Yes" : "No",
      },
      { label: "Additional", value: capabilities.additionalCapabilities },
    ];
  };
  const convertSptc = (sptc) => {
    return [
      { label: "Onco", value: sptc.onco },
      { label: "Supply status", value: sptc.supplyStatus },
      { label: "Hormonal", value: sptc.hormonal },
      {
        label: "Drug Device combinations",
        value: sptc.drugDeviceCombinations ? "Yes" : "",
      },
      {
        label: "Clinical batch manufacturing",
        value: sptc.clinicalBatchManufacturing,
      },
    ];
  };
  const convertManufacturingDetails = (manufacturingDetails) => {
    return [
      { label: "Dosage Form", value: manufacturingDetails.dosageForm },
      { label: "Tablets", value: manufacturingDetails.tablets },
      { label: "Dose form 2", value: manufacturingDetails.doseForm2 },
      { label: "Special capabilities", value: "" }, // No data for special capabilities
      { label: "Onco", value: "GMP 1" }, // Hardcoded based on the example
      { label: "Drug Device combi", value: "GMP 1/ GMP 2" }, // Hardcoded based on the example
      {
        label: "Region of Manufacture",
        value: manufacturingDetails.regionOfManufacture,
      },
      {
        label: "Other Accreditations",
        value: manufacturingDetails.otherAccreditations[0],
      },
      {
        label: "",
        value: manufacturingDetails.otherAccreditations.slice(1).join(", "),
      },
    ];
  };
  const convertCommercials = (commercials) => {
    return [
      { label: "Solid orals cc", value: commercials.solidOralsCc },
      { label: "Tabs", value: commercials.tabs },
      { label: "Caps", value: commercials.caps },
      { label: "Types", value: commercials.types.join(", ") },
    ];
  };

  return (
    <div className="p14 backgroundColor">
      <BreadCrumbs array={urls} />
      <div className="p-4 mt-4 bg-white">
        <div className="ps_main mb-4">
          <div>
            <h1 className="fw-600">Supplier Details</h1>
          </div>
          <FirstButton
            className="borderbtn"
            buttonText="Back to Search Results"
            width="30%"
            onClick={() => navigate("/supplier-search")}
          />
        </div>
        <div className="ps_main ">
          <p className="fs-16 fw-600">{data?.title}</p>
          <FirstButton
            className="btn"
            buttonText="Request Term Sheet"
            width="30%"
            onClick={() => navigate(`/supplier-term-sheet/${id}`)}
          />
        </div>
      </div>
      <div>
        <hr />
        <div className="dfcg3">
          <div className="flex1">
            <h2 className="my-2  heading-bg-color text-white">Capabilities</h2>
            <table class="table table-striped bx-shadow">
              <tbody>
                {Capabilities &&
                  Capabilities.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <h2 className="my-2  heading-bg-color text-white">Commercials</h2>
            <table class="table table-striped bx-shadow">
              <tbody>
                {commercials &&
                  commercials.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="flex1">
            <h2 className="my-2  heading-bg-color text-white">
              Special technologies & Capabilities
            </h2>
            <table class="table table-striped bx-shadow">
              <tbody>
                {Sptc &&
                  Sptc.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <h2 className="my-2  heading-bg-color text-white">
              Manufacturing Details
            </h2>

            <table class="table table-striped bx-shadow">
              <tbody>
                {manufDetails &&
                  manufDetails.map((item, index) => (
                    <tr key={index}>
                      <td className="p-3">{item.label}</td>
                      <td className="p-3">{item.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <h2 className="my-2" style={{ textAlign: "center" }}>
        Track Record
      </h2>
      <div className="my-2 bx-shadow p2 bg-white">
        <p className="fs-16">Strengths of the seller</p>
        <p className="fs-16">Track record of the seller</p>
        <p className="fs-16">Star rating</p>
      </div>
      <FirstButton
        className="btn my-2"
        buttonText="Request Term Sheet"
        width="100%"
        onClick={() => navigate(`/supplier-term-sheet/${id}`)}
      />
    </div>
  );
};

export default SupplierDetails;
