import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FirstButton from "../common/button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  filters,
  // searchResult,
  about,
  list,
  list2,
  list3,
  list4,
  list5,
} from "../data/SupplierData";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AccordionDetails from "@mui/material/AccordionDetails";
import { TextField, Card } from "@material-ui/core";
import BreadCrumbs from "./BreadCrumbs";
import { Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import Dropdown from "../common/dropdown";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import noData from "../assets/images/noData.png";
const SupplierSearchPage = () => {
  const navigate = useNavigate();
  const [searchTerms, setSearchTerms] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [search, setSearch] = useState("");
  const [count, setCount] = useState("");
  const initialState = {
    Molecule: {
      ATCClass: null,
      DosageForm: null,
      RouteOfAdministration: null,
    },
    dates: {
      Before: "",
      After: "",
    },
    checkboxes: {
      Metered: false,
      Chewing: false,
      LongActing: false,
      ExtendedRelease: false,
      OrallyDisintegrating: false,
      Sublingual: false,

      // ---------------------------------
      DelayedRelease: false,
      DosageFormsAerosol: false,
      DosageFormsAerosolMetered: false,
      DosageFormsCapsule: false,
      DosageFormsCapsuleDelayedRelease: false,
      DosageFormsCapsuleExtendedRelease: false,
      DosageFormsCream: false,
      DosageFormsDressing: false,
      DosageFormsEnema: false,
      DosageFormsGas: false,
      DosageFormsGel: false,
      DosageFormsGranule: false,
      DosageFormsGranuleDelayedRelease: false,
      DosageFormsGranuleExtendedRelease: false,
      DosageFormsGumChewing: false,
      DosageFormsImplant: false,
      DosageFormsInjectable: false,
      DosageFormsInjectableLongActing: false,
      DosageFormsIntrauterineDevice: false,
      DosageFormsLiquidDrops: false,
      DosageFormsLotion: false,
      DosageFormsLozenge: false,
      DosageFormsMultiple: false,
      DosageFormsOintment: false,
      DosageFormsOtherSpecialForms: false,
      DosageFormsOvule: false,
      DosageFormsPaste: false,
      DosageFormsPatch: false,
      DosageFormsPellet: false,
      DosageFormsPowder: false,
      DosageFormsPowderDelayedRelease: false,
      DosageFormsPowderExtendedRelease: false,
      DosageFormsRing: false,
      DosageFormsShampoo: false,
      DosageFormsSoap: false,
      DosageFormsSolution: false,
      DosageFormsSpray: false,
      DosageFormsSuppository: false,
      DosageFormsSuspension: false,
      DosageFormsSuspensionDelayedRelease: false,
      DosageFormsSuspensionExtendedRelease: false,
      DosageFormsTablet: false,
      DosageFormsTabletDelayedRelease: false,
      DosageFormsTabletExtendedRelease: false,
      DosageFormsTabletOrallyDisintegrating: false,
      DosageFormsTabletSublingual: false,
      DosageFormsTabletSublingualDelayedRelease: false,
      DosageFormsTampon: false,
      TypeBulkPackaging: false,
      TypeManufacturing: false,
      TypePackaging: false,
      TypeQCtestingandrelease: false,
      SpecialcapabilitiesHormonalhandling: false,
      SpecialcapabilitiesOncologyproducts: false,
      SpecialcapabilitiesPotentcompoundscapability: false,
      SpecialcapabilitiesSoftgelatincapsules: false,
      PlantLocationAsia: false,
      PlantLocationEurope: false,
      PlantLocationNorthAmerica: false,
      PlantLocationRestoftheWorld: false,
      RegulatoryComplianceEMAEU: false,
      RegulatoryComplianceHealthCanada: false,
      RegulatoryComplianceMHRA: false,
      RegulatoryComplianceTGA: false,
      RegulatoryComplianceUSFDA: false,
      RegulatoryComplianceANVISA: false,
      RegulatoryComplianceHSA: false,
      RegulatoryCompliancePICS: false,
      RegulatoryCompliancePMDA: false,
      RegulatoryComplianceRussianGMP: false,
      RegulatoryComplianceWHO: false,
    },
  };

  const [formState, setFormState] = useState(initialState);
  const [checkformState, setCheckFormState] = useState(initialState);
  const urls = [
    { name: "Home", url: "/home" },
    { name: "Supplier Search", url: "" },
  ];
  const [value, setValue] = useState("2");
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
    getSupplier(1, "");
  }, []);

  const getSupplier = (page, search) => {
    const data = {
      page: page,
      limit: 3,
      searchChar: search,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "get-supplier", data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((resp) => {
        setSearchResult(resp?.data?.data);
        const totalObjects = resp?.data?.totalcount;
        setCount(totalObjects);
        const totalPages = Math.ceil(totalObjects / 3);
        setTotalPage(totalPages);
        setPage(page);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = () => {
    getSupplier(1, search);
  };
  const handleChange = (event, newValue) => {
    if (newValue === "1") {
      navigate("/dossier-search");
    } else if (newValue === "2") {
      navigate("/supplier-search");
    }
    setValue(newValue);
  };
  const handleSearchChange = (filterId, e) => {
    setSearchTerms({
      ...searchTerms,
      [filterId]: e.target.value.toLowerCase(),
    });
  };

  const handleDateChange = (name, value) => {
    const updatedState = {
      ...formState,
      dates: {
        ...formState.dates,
        [name]: value,
      },
    };
    setFormState(updatedState);

    searchSupplier(updatedState); // Pass updated state to searchSupplier
  };

  const normalizeKey = (category, name) => {
    return `${category}${name}`
      .replace(/[\s-,]/g, "") // Removes spaces, hyphens, and commas
      .replace(/\((.*?)\)/g, "$1") // Removes parentheses but keeps their content
      .replace(/\//g, ""); // Removes any slashes
  };
  const handleCheckboxChange = (category, name, checked) => {
    const keyName = normalizeKey(category, name);
    const updatedState = {
      ...formState,
      checkboxes: {
        ...formState.checkboxes,
        [keyName]: checked,
      },
    };
    setFormState(updatedState);

    searchSupplier(updatedState); // Pass updated state to searchSupplier
  };

  const searchSupplier = (updatedState, pageNo) => {
    console.log(pageNo, "PAGE");
    const data = {
      page:
        pageNo === "" || pageNo === null || pageNo === undefined ? 1 : pageNo,
      limit: 3,
      searchChar: search,
      type: {
        Manufacturing: updatedState?.checkboxes?.TypeManufacturing,
        Packaging: updatedState?.checkboxes?.TypePackaging,
        BulkPackaging: updatedState?.checkboxes?.TypeBulkPackaging,
        testingAndRelease: updatedState?.checkboxes?.TypeQCtestingandrelease,
      },
      dosageForms: {
        Aerosol: updatedState?.checkboxes?.DosageFormsAerosol,
        AerosolMetered: updatedState?.checkboxes?.DosageFormsAerosolMetered,
        Capsule: updatedState?.checkboxes?.DosageFormsCapsule,
        CapsuleDelayedRelease:
          updatedState?.checkboxes?.DosageFormsCapsuleDelayedRelease,
        CapsuleExtendedRelease:
          updatedState?.checkboxes?.DosageFormsCapsuleExtendedRelease,
        Cream: updatedState?.checkboxes?.DosageFormsCream,
        Dressing: updatedState?.checkboxes?.DosageFormsDressing,
        Enema: updatedState?.checkboxes?.DosageFormsEnema,
        Gas: updatedState?.checkboxes?.DosageFormsGas,
        Granule: updatedState?.checkboxes?.DosageFormsGranule,
        GranuleDelayedRelease:
          updatedState?.checkboxes?.DosageFormsGranuleDelayedRelease,
        GranuleExtendedRelease:
          updatedState?.checkboxes?.DosageFormsGranuleExtendedRelease,
        GumChewing: updatedState?.checkboxes?.DosageFormsGumChewing,
        Implant: updatedState?.checkboxes?.DosageFormsGas,
        Injectable: updatedState?.checkboxes?.DosageFormsInjectable,
        InjectableLongActing:
          updatedState?.checkboxes?.DosageFormsIntrauterineDevice,
        IntrauterineDevice:
          updatedState?.checkboxes?.DosageFormsIntrauterineDevice,
        LiquidDrops: updatedState?.checkboxes?.DosageFormsLiquidDrops,
        Lotion: updatedState?.checkboxes?.DosageFormsLotion,
        Lozenge: updatedState?.checkboxes?.DosageFormsLozenge,
        Multiple: updatedState?.checkboxes?.DosageFormsMultiple,
        Ointment: updatedState?.checkboxes?.DosageFormsOintment,
        OtherSpecialForms:
          updatedState?.checkboxes?.DosageFormsOtherSpecialForms,
        Ovule: updatedState?.checkboxes?.DosageFormsOvule,
        Paste: updatedState?.checkboxes?.DosageFormsPaste,
        Patch: updatedState?.checkboxes?.DosageFormsPatch,
        Pellet: updatedState?.checkboxes?.DosageFormsPellet,
        Powder: updatedState?.checkboxes?.DosageFormsPowder,
        PowderDelayedRelease:
          updatedState?.checkboxes?.DosageFormsPowderDelayedRelease,
        PowderExtendedRelease:
          updatedState?.checkboxes?.DosageFormsPowderExtendedRelease,
        Ring: updatedState?.checkboxes?.DosageFormsRing,
        Shampoo: updatedState?.checkboxes?.DosageFormsShampoo,
        Soap: updatedState?.checkboxes?.DosageFormsSoap,
        Solution: updatedState?.checkboxes?.DosageFormsSolution,
        Spray: updatedState?.checkboxes?.DosageFormsSpray,
        Suppository: updatedState?.checkboxes?.DosageFormsSuppository,
        Suspension: updatedState?.checkboxes?.DosageFormsSuspension,
        SuspensionDelayedRelease:
          updatedState?.checkboxes?.DosageFormsSuspensionDelayedRelease,
        SuspensionExtendedRelease:
          updatedState?.checkboxes?.DosageFormsSuspensionExtendedRelease,
        Tablet: updatedState?.checkboxes?.DosageFormsTablet,
        TabletDelayedRelease:
          updatedState?.checkboxes?.DosageFormsTabletDelayedRelease,
        TabletExtendedRelease:
          updatedState?.checkboxes?.DosageFormsTabletExtendedRelease,
        TabletOrallyDisintegrating: updatedState?.checkboxes?.DosageFormsGas,
        TabletSublingual: updatedState?.checkboxes?.DosageFormsTabletSublingual,
        TabletSublingualDelayedRelease:
          updatedState?.checkboxes?.DosageFormsTablet,
        Tampon: updatedState?.checkboxes?.DosageFormsTampon,
        Gel: updatedState?.checkboxes?.DosageFormsGel,
      },
      specialCapabilities: {
        HormonalHandling:
          updatedState?.checkboxes?.SpecialcapabilitiesHormonalhandling,
        OncologyProducts:
          updatedState?.checkboxes?.SpecialcapabilitiesOncologyproducts,
        PotentCompoundsCapability:
          updatedState?.checkboxes
            ?.SpecialcapabilitiesPotentcompoundscapability,
        SoftGelatinCapsules:
          updatedState?.checkboxes?.SpecialcapabilitiesSoftgelatincapsules,
      },
      plantLocation: {
        Asia: updatedState?.checkboxes?.PlantLocationAsia,
        Europe: updatedState?.checkboxes?.PlantLocationEurope,
        NorthAmerica: updatedState?.checkboxes?.PlantLocationNorthAmerica,
        RestOfTheWorld: updatedState?.checkboxes?.PlantLocationRestoftheWorld,
      },
      RegulatoryCompliance: {
        USFDA: updatedState?.checkboxes?.RegulatoryComplianceUSFDA,
        EMAEU: updatedState?.checkboxes?.RegulatoryComplianceEMAEU,
        TGA: updatedState?.checkboxes?.RegulatoryComplianceTGA,
        HealthCanada:
          updatedState?.checkboxes?.RegulatoryComplianceHealthCanada,
        MHRA: updatedState?.checkboxes?.RegulatoryComplianceMHRA,
        RussianGMP: updatedState?.checkboxes?.RegulatoryComplianceRussianGMP,
        ANVISA: updatedState?.checkboxes?.RegulatoryComplianceANVISA,
        WHO: updatedState?.checkboxes?.RegulatoryComplianceWHO,
        PICS: updatedState?.checkboxes?.RegulatoryCompliancePICS,
        HSA: updatedState?.checkboxes?.RegulatoryComplianceHSA,
        PMDA: updatedState?.checkboxes?.RegulatoryCompliancePMDA,
      },
    };

    axios
      .post(process.env.REACT_APP_BACKEND + "search-supplier", data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((resp) => {
        setSearchResult(resp?.data?.data);
        const totalObjects = resp?.data?.totalCount;
        setCount(totalObjects);
        const totalPages = Math.ceil(totalObjects / 3);
        setTotalPage(totalPages);
        if (pageNo === "" || pageNo === null || pageNo === undefined) {
          setPage(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlePageChange = (event, value) => {
    setPage(value);
    if (formState === checkformState) {
      getSupplier(value, "");
    } else {
      searchSupplier(formState, value);
    }
  };
  return (
    <div className="search backgroundColor">
      <BreadCrumbs array={urls} />
      <div className="only-d-flex justify-center align-center mt-3">
        <Tabs
          value={value}
          onChange={handleChange}
          className="w30 "
          sx={{
            "& button": { borderRadius: 2 },
            "& button:hover": {
              backgroundColor: "#7915cf",
              color: "white",
              opacity: 0.55,
            },
            "& button:focus": {
              backgroundColor: "#7915cf",
              color: "white",
            },
            "& button:active": {
              backgroundColor: "#7915cf",
              color: "white",
            },
            "& .MuiTabs-indicator": {
              display: "none", // Hide the indicator line
            },
          }}
        >
          <Tab
            className={
              value === "1" ? "fs-14 fw-600  activeTab" : "fs-14 fw-600 "
            }
            value="1"
            label="Dossier Finder"
          />
          <Tab
            className={
              value === "2" ? "fs-14 fw-600  activeTab" : "fs-14 fw-600 "
            }
            value="2"
            label="Supplier Finder  "
          />
        </Tabs>
      </div>
      <div className="searchInput">
        <input
          className="login_input bg-white"
          placeholder="Search"
          style={{ width: "60%" }}
          value={search}
          onChange={(e) => {
            const value = e.target.value;
            setSearch(value);
            if (value.length === 0) {
              getSupplier(1, "");
            }
          }}
        />

        <FirstButton
          buttonText="Submit"
          onClick={handleSubmit}
          className="btn ms-3"
        />
      </div>

      <div className="dflex ">
        <div className="w20">
          <h2 className="main_imgcont2 fs-16 my-2">Filters</h2>
        </div>
        <div className="w60">
          <h2 className="main_imgcont2 fs-16 my-2 ">{count} suppliers found</h2>
        </div>
      </div>
      <div className="dflex ">
        <div className="w20  m121">
          {filters &&
            filters.map((item, index) => (
              <Accordion
                key={index}
                // expanded={expandedAccordion === index} // Only expand if the current accordion matches the state
                // onChange={() => handleAccordionChange(index)} // Handle accordion change
              >
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  className="checkBox "
                >
                  <h4>{item.name}</h4>
                </AccordionSummary>
                <AccordionDetails className="px-3 py-2 text-start">
                  {/* Search Box for the specific checkbox list */}
                  {item.id !== 0 && item?.id !== 3 && (
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      fullWidth
                      value={searchTerms[item.id] || ""}
                      onChange={(e) => handleSearchChange(item.id, e)}
                      style={{ marginBottom: "1rem" }}
                    />
                  )}

                  {item.subFilters &&
                    item.subFilters
                      .filter((itemSub) =>
                        itemSub
                          .toLowerCase()
                          .includes(searchTerms[item.id] || "")
                      )
                      .map((itemSub, subIndex) => (
                        <AccordionDetails className="px-3 py-2" key={subIndex}>
                          {item.id === 0 ? (
                            <Dropdown
                              MenuList={
                                index === 0 ? (
                                  list
                                ) : index === 1 ? (
                                  list2
                                ) : index === 2 ? (
                                  list3
                                ) : index === 3 ? (
                                  list4
                                ) : index === 4 ? (
                                  list5
                                ) : (
                                  <></>
                                )
                              }
                              dValue={
                                index === 0 ? (
                                  { value: "1", label: "Therapeutic Category" }
                                ) : index === 1 ? (
                                  { value: "1", label: "Dosage Form " }
                                ) : index === 2 ? (
                                  {
                                    value: "1",
                                    label: "Route Of Administration ",
                                  }
                                ) : index === 3 ? (
                                  {}
                                ) : index === 4 ? (
                                  {}
                                ) : (
                                  <></>
                                )
                              }
                            />
                          ) : (
                            <FormControlLabel
                              control={
                                item.id === 3 ? (
                                  <input
                                    type="date"
                                    defaultValue="Small"
                                    className="p-2"
                                    onChange={(e) =>
                                      handleDateChange(itemSub, e.target.value)
                                    }
                                  />
                                ) : (
                                  <Checkbox
                                    checked={
                                      formState.checkboxes[
                                        item.name.replace(/[\s(),-]+/g, "") +
                                          itemSub.replace(/[\s(),-]+/g, "")
                                      ] || false
                                    }
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        item.name.replace(/\s+/g, ""), // Category (e.g., "Dossierreadiness")
                                        itemSub.replace(/\s+/g, ""), // Name (e.g., "ReadyUS")
                                        e.target.checked
                                      )
                                    }
                                    sx={{
                                      color: " #4c8df5",
                                      "&.Mui-checked": { color: "#4c8df5" },
                                      "& .MuiSvgIcon-root": { fontSize: 24 },
                                    }}
                                  />
                                )
                              }
                              labelPlacement={item.id === 3 ? "start" : "end"}
                              label={
                                <span
                                  style={
                                    item.id === 3 && itemSub === "After"
                                      ? {
                                          fontSize: "1.4rem",
                                          marginRight: "6.2rem",
                                          fontWeight: "400",
                                        }
                                      : item.id === 3
                                      ? {
                                          fontSize: "1.4rem",
                                          marginRight: "5rem",
                                          fontWeight: "400",
                                        }
                                      : {
                                          fontSize: "1.4rem",
                                          marginLeft: "1rem",
                                          fontWeight: "400",
                                        }
                                  }
                                >
                                  {itemSub}
                                </span>
                              }
                            />
                          )}
                        </AccordionDetails>
                      ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
        {count > 0 ? (
          <>
            <div className="w60">
              {searchResult &&
                searchResult.map((sitem, index) => (
                  <div className="ph-spcardmain bg-white" key={sitem?._id}>
                    <div className="ph-cardsphead">
                      <div>
                        <h2
                          className="fs-24 fw-600 sidebar_close title-hover"
                          onClick={() =>
                            navigate(`/supplier-details/${sitem?._id}`)
                          }
                        >
                          {sitem?.title}
                        </h2>
                        <p className="location">
                          <span className="mb-1 me-1">
                            <LocationOnIcon />
                          </span>
                          {sitem?.location?.Asia
                            ? "Asia"
                            : sitem?.location?.Europe
                            ? "Europe"
                            : sitem?.location?.NorthAmerica
                            ? "North America"
                            : sitem?.location?.RestoftheWorld
                            ? "Rest of the World"
                            : ""}
                        </p>
                      </div>
                      <div style={{ textAlign: "end" }}>
                        <FirstButton
                          buttonText="Enquire"
                          className="enquire-btn"
                          onClick={() =>
                            navigate(`/supplier-term-sheet/${sitem?._id}`)
                          }
                        />
                        <p className="offer-font">
                          Offer in {sitem?.offDay} business days
                        </p>
                      </div>
                    </div>
                    <div className="ph-cardspbody">
                      <div>
                        <p className="ssp_thead mb-0 ">Capabilities</p>
                      </div>
                      <div className="ssp_pilcont">
                        {/* {sitem?.capabilities.map((citems, index) => (
                      <p className="vpil" key={citems.id}>
                        {citems.value}
                      </p>
                    ))} */}
                        {sitem?.capabilities &&
                          Object.entries(sitem.capabilities).map(
                            ([key, value], index) =>
                              value && ( // Only display if the value is true
                                <p className="vpil" key={index}>
                                  {key}
                                </p>
                              )
                          )}
                      </div>
                    </div>
                    <div className="ph-cardspbody" style={{ border: "none" }}>
                      <div>
                        <p className="ssp_thead mb-0 ">Dosage</p>
                      </div>
                      <div className="ssp_pilcont">
                        {/* {sitem?.dosage.map((citems, index) => (
                      <p className="vpil" key={citems.id}>
                        {citems.value}
                      </p>
                    ))} */}
                        {sitem?.dosage &&
                          Object.entries(sitem.dosage).map(
                            ([key, value], index) =>
                              value && ( // Only display if the value is true
                                <p className="vpil" key={index}>
                                  {key}
                                </p>
                              )
                          )}
                      </div>
                    </div>
                    <div className="ph-cardspbody">
                      <div>
                        <p className="ssp_thead mb-0 ">Regulatory Compliance</p>
                      </div>
                      <div className="ssp_pilcont">
                        {/* {sitem?.rc.map((citems, index) => (
                      <p className="vpil" key={citems.id}>
                        {citems.value}
                      </p>
                    ))} */}
                        {sitem?.rc &&
                          Object.entries(sitem.rc).map(
                            ([key, value], index) =>
                              value && ( // Only display if the value is true
                                <p className="vpil" key={index}>
                                  {key}
                                </p>
                              )
                          )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="w20">
              {about &&
                about.map((item, index) => (
                  <div className="ph-spcardmain2 bg-white">
                    <h2 className="fs-20 fw-600 px1">{item.title}</h2>
                    <h4 className="fs-16 fw-500 mb-3">{item?.subHead}</h4>
                    <p className="about-molecule text-justify">{item.descp}</p>
                    <FirstButton
                      className="btn my-4 mx-auto d-flex"
                      buttonText={item?.buttonText}
                      onClick={() => navigate(item?.navi)}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <Card className="px-2 w100">
            <div className="d-flex flex-column align-items-center p-3">
              <img src={noData} style={{ width: "25%", height: "auto" }} />
              <p className="text-center fs-14 fw-500 light-text-color">
                No Supplier Found
              </p>
            </div>
          </Card>
        )}
      </div>
      <div className="d-flex justify-center">
        <Pagination
          count={totalPage}
          page={page}
          defaultPage={1}
          variant="outlined"
          onChange={handlePageChange}
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default SupplierSearchPage;
